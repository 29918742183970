import React, { useEffect } from "react";
import "../../styles/check-button.css";
import "../../styles/custom-button.css";

export function CustomButton(props) {
	let animateButton = function (e) {
		// console.log("animateButton");
		e.preventDefault();
		//reset animation
		e.currentTarget.classList.add("animate");
	};

	function setAnimation() {
		let checkAnswerButton = document.querySelector(
			".check-answer-button.bubbly-button"
		);

		if (checkAnswerButton)
			checkAnswerButton.addEventListener("click", animateButton, false);
	}

	function removeAnimatedClass(event) {
		event.currentTarget.classList.remove("animate");
	}

	useEffect(() => {
		setAnimation();
	}, []);

	return (
		<div
			className={`check-answer-button bubbly-button custom-button ${props.className}`}
			onClick={props.onClick}
			style={props.style}
			onLoad={setAnimation}
			onAnimationEnd={removeAnimatedClass}
		>
			{props.noHighlight ? "" : <div></div>}
			{/* <img
				src={require("../../assets/check_button.svg").default}
				style={props.imageStyle}
			/> */}
			<h1 className={props.titleClassName}>{props.title}</h1>
		</div>
	);
}
