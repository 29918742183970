import React, { useEffect, useState } from "react";
import "../styles/billing-informations.css";
import { useLocation, useNavigate } from "react-router-dom";
import SubscriptionInformationsForm from "../components/forms/SubscriptionInformationsForm";
import PopoverForm from "../components/PopoverForm";
import SubscriptionInformationsFormMobile from "../components/forms/mobile/SubscriptionInformationsFormMobile";
import { isMobileDevice } from "../scripts/helpers";

export default function SubscriptionInformations() {
	const navigate = useNavigate();
	const location = useLocation();
	const [subscriptionType, setSubscriptionType] = useState("");

	useEffect(() => {
		location.state
			? setSubscriptionType(location.state.subscriptionType)
			: navigate("/subscription");

		document.querySelector("body").classList.add("background-blue");
		document.querySelector(".billing-info").click();
		return () => {
			document.querySelector("body").classList.remove("background-blue");
		};
	}, []);
	return (
		<PopoverForm
			title="Date de facturare"
			openButtonContent={<></>}
			openButtonClassName="open-popup billing-info"
			form={
				isMobileDevice() ? (
					<SubscriptionInformationsFormMobile
						subscriptionType={subscriptionType}
					/>
				) : (
					<SubscriptionInformationsForm subscriptionType={subscriptionType} />
				)
			}
			keepOpen={true}
			popupClassName="big-popup"
		/>
	);
}
