import React from "react";
import { useState } from "react";
import Navbar__Mobile from "./Navbar__Mobile";
import Navbar from "./Navbar";
import Cookies from "js-cookie";
import config from "../../config";
import jwt_decode from "jwt-decode";
import { useEffect } from "react";
import { isMobileDevice } from "../../scripts/helpers";

export default function NavbarComponent({ noNavbar }) {
	const [name, setName] = useState("");
	useEffect(() => {
		let query = new URLSearchParams(window.location.search);

		if (!Cookies.get(config.cookie_token_key) && !query.get('sid')) {
			window.open("/", "_self");
		} else {
			try {
				if (query.get('sid') && !Cookies.get(config.cookie_token_key))	{
					Cookies.set(config.cookie_token_key, query.get('sid'));
				}
				let token = Cookies.get(config.cookie_token_key) ?? query.get('sid');
				let decoded = jwt_decode(token);
				setName(decoded.data.name.split(" ")[0]);
			} catch (e) {
				console.log('NAVBAR');
				console.log('EXCEPTION: ', e);
				window.open("/", "_self");
			}
		}
	}, []);
	if (!noNavbar)
		if (isMobileDevice()) {
			return <Navbar__Mobile name={name} />;
		} else {
			return <Navbar />;
		}
	else {
		return <></>;
	}
}
