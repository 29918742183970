import axios from "axios";
import React, { useEffect } from "react";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { CustomButton } from "../components/modules/CustomButton";
import { releaseDate } from "../scripts/helpers";
import "../styles/countdown-page.css";
import Clouds from "./Clouds";

export default function Countdown() {
	const navigate = useNavigate();
	const [showGoToHome, setShowGoToHome] = useState(false);
	const AnimatedCard = ({ animation, digit }) => {
		return (
			<div className={`flipCard ${animation}`}>
				<span>{digit}</span>
			</div>
		);
	};

	// function component
	const StaticCard = ({ position, digit }) => {
		return (
			<div className={position}>
				<span>{digit}</span>
			</div>
		);
	};

	// function component
	const FlipUnitContainer = ({ digit, shuffle, unit }) => {
		// assign digit values
		let currentDigit = digit;
		let previousDigit = digit + 1;

		if (unit === "seconds2" || unit === "minutes2") {
			previousDigit = previousDigit === 10 ? 0 : previousDigit;
		} else if (unit === "seconds1" || unit === "minutes1") {
			previousDigit = previousDigit === 6 ? 0 : previousDigit;
		} else if (unit === "hours1") {
			previousDigit = previousDigit === -1 ? 2 : previousDigit;
		} else if (unit === "hours2") {
			previousDigit = previousDigit === -1 ? 3 : previousDigit;
		}

		const digit1 = shuffle ? previousDigit : currentDigit;
		const digit2 = !shuffle ? previousDigit : currentDigit;

		// shuffle animations
		const animation1 = shuffle ? "fold" : "unfold";
		const animation2 = !shuffle ? "fold" : "unfold";

		return (
			<div className={"flipUnitContainer "}>
				<StaticCard position={"upperCard"} digit={currentDigit} />
				<StaticCard position={"lowerCard"} digit={previousDigit} />
				<AnimatedCard digit={digit1} animation={animation1} />
				<AnimatedCard digit={digit2} animation={animation2} />
			</div>
		);
	};

	// class component
	class FlipClock extends React.Component {
		constructor(props) {
			super(props);
			this.state = {
				hours1: 0,
				hoursShuffle1: true,
				minutes1: 0,
				minutesShuffle1: true,
				seconds1: 0,
				secondsShuffle1: true,
				days1: 0,
				daysShuffle1: true,
				hours2: 0,
				hoursShuffle2: true,
				minutes2: 0,
				minutesShuffle2: true,
				seconds2: 0,
				secondsShuffle2: true,
				days2: 0,
				daysShuffle2: true,
			};
		}

		componentDidMount() {
			this.timerID = setInterval(() => this.updateTime(), 50);
		}

		componentWillUnmount() {
			clearInterval(this.timerID);
		}

		updateTime() {
			// get new date
			const time_future = releaseDate;
			const time_present = new Date();
			// set time units

			const difference_in_milliseconds =
				time_future.getTime() - time_present.getTime();
			const days = parseInt(difference_in_milliseconds / (1000 * 3600 * 24));
			const hours = parseInt((difference_in_milliseconds / (1000 * 3600)) % 24);
			const minutes = parseInt((difference_in_milliseconds / (1000 * 60)) % 60);
			const seconds = parseInt((difference_in_milliseconds / 1000) % 60);

			if (difference_in_milliseconds <= 0) {
				setShowGoToHome(true);
			} else {
				const hours1 = parseInt(hours / 10);
				const hours2 = parseInt(hours % 10);
				const minutes1 = parseInt(minutes / 10);
				const minutes2 = parseInt(minutes % 10);
				const seconds1 = parseInt(seconds / 10);
				const seconds2 = parseInt(seconds % 10);
				const days1 = parseInt(days / 10);
				const days2 = parseInt(days % 10);

				if (hours1 !== this.state.hours1) {
					const hoursShuffle1 = !this.state.hoursShuffle1;
					this.setState({
						hours1,
						hoursShuffle1,
					});
				}
				if (hours2 !== this.state.hours2) {
					const hoursShuffle2 = !this.state.hoursShuffle2;
					this.setState({
						hours2,
						hoursShuffle2,
					});
				}
				if (minutes1 !== this.state.minutes1) {
					const minutesShuffle1 = !this.state.minutesShuffle1;
					this.setState({
						minutes1,
						minutesShuffle1,
					});
				}
				if (minutes2 !== this.state.minutes2) {
					const minutesShuffle2 = !this.state.minutesShuffle2;
					this.setState({
						minutes2,
						minutesShuffle2,
					});
				}
				if (seconds1 !== this.state.seconds1) {
					const secondsShuffle1 = !this.state.secondsShuffle1;
					this.setState({
						seconds1,
						secondsShuffle1,
					});
				}
				if (seconds2 !== this.state.seconds2) {
					const secondsShuffle2 = !this.state.secondsShuffle2;
					this.setState({
						seconds2,
						secondsShuffle2,
					});
				}
				if (days1 !== this.state.days1) {
					const daysShuffle1 = !this.state.daysShuffle1;
					this.setState({
						days1,
						daysShuffle1,
					});
				}
				if (days2 !== this.state.days2) {
					const daysShuffle2 = !this.state.daysShuffle2;
					this.setState({
						days2,
						daysShuffle2,
					});
				}
			}
		}

		render() {
			// state object destructuring
			const {
				days1,
				hours1,
				minutes1,
				seconds1,
				daysShuffle1,
				hoursShuffle1,
				minutesShuffle1,
				secondsShuffle1,
				days2,
				hours2,
				minutes2,
				seconds2,
				daysShuffle2,
				hoursShuffle2,
				minutesShuffle2,
				secondsShuffle2,
			} = this.state;

			return (
				<div className={`flipClock ${showGoToHome ? "animate" : ""}`}>
					<div className="days clock-item">
						<FlipUnitContainer
							unit={"days1"}
							digit={days1}
							shuffle={daysShuffle1}
						/>
						<FlipUnitContainer
							unit={"days2"}
							digit={days2}
							shuffle={daysShuffle2}
						/>
						<div className="clock-item-description">
							<h1>zile</h1>
						</div>
					</div>
					<div className="hours clock-item">
						<FlipUnitContainer
							unit={"hours1"}
							digit={hours1}
							shuffle={hoursShuffle1}
						/>
						<FlipUnitContainer
							unit={"hours2"}
							digit={hours2}
							shuffle={hoursShuffle2}
						/>
						<div className="clock-item-description">
							<h1>ore</h1>
						</div>
					</div>
					<div className="minutes clock-item">
						<FlipUnitContainer
							unit={"minutes1"}
							digit={minutes1}
							shuffle={minutesShuffle1}
						/>
						<FlipUnitContainer
							unit={"minutes2"}
							digit={minutes2}
							shuffle={minutesShuffle2}
						/>
						<div className="clock-item-description">
							<h1>minute</h1>
						</div>
					</div>
					<div className="seconds clock-item">
						<FlipUnitContainer
							unit={"seconds1"}
							digit={seconds1}
							shuffle={secondsShuffle1}
						/>
						<FlipUnitContainer
							unit={"seconds2"}
							digit={seconds2}
							shuffle={secondsShuffle2}
						/>
						<div className="clock-item-description">
							<h1>secunde</h1>
						</div>
					</div>
				</div>
			);
		}
	}

	useEffect(() => {
		document.querySelector("body").style.padding = "0";
		document.querySelector("body").style.overflowX = "hidden";
		document.querySelector("body").style.overflowY = "scroll";
		return () => {
			document.querySelector("body").style.padding = "2% 5%";
			document.querySelector("body").style.overflow = "hidden";
		};
	}, []);

	return (
		<div className="countdown-container">
			<Clouds />
			<img
				style={{ height: "50px", zIndex: 2 }}
				src={require("../assets/logo_WS/logo_wiki_side.svg").default}
			/>
			<div className="wiki-party-container">
				<img
					className="wiki-party"
					src={require("../assets/mascot/wiki_party.svg").default}
				/>
			</div>

			<h1 className="countdown-title">Ne vedem în curând!</h1>
			<FlipClock />
			{showGoToHome ? (
				<CustomButton
					style={{ zIndex: 10 }}
					title="Acasa"
					onClick={() => navigate("/")}
				/>
			) : (
				""
			)}
		</div>
	);
}
