import React, { useEffect, useState } from "react";

import RotateScreen from "../views/RotateScreen";
import "../styles/globals__mobile.css";
import Cookies from "js-cookie";
import config from "../config";
import NavbarComponent from "./Navbar/NavbarComponent";
import { isMobileDevice } from "../scripts/helpers";
let setDimensions = true;
export default function MobileContainer(props) {
	const [orientation, setOrientation] = useState("");
	const [windowWidth, setWindowWidth] = useState(0);
	const [windowHeight, setWindowHeight] = useState(0);

	function setHeightWidth() {
		if (
			orientation == "landscape" &&
			props.correctOrientation == "landscape" &&
			props.page != "login" &&
			setDimensions
		) {
			setTimeout(() => {
				let height = Math.min(window.innerHeight, window.innerWidth);
				let width = Math.max(window.innerHeight, window.innerWidth);
				setWindowHeight(height);
				setWindowWidth(width);
				document.querySelector(".mobile-landscape-container").style.height =
					height + "px";
				document.querySelector(".mobile-landscape-container").style.width =
					width + "px";
			}, 200);
		}
	}

	useEffect(() => {
		setOrientation("landscape");
		// console.log("window object: ", Object.keys(window));
		// console.log("window object screen: ", Object.keys(window.screen));
		if (
			"screen" in window &&
			"orientation" in window.screen &&
			"angle" in window.screen.orientation
		) {
			// console.log("INTRA IN IF");
			if (
				window.screen.orientation.angle !== 0 &&
				window.screen.orientation.angle !== 180 &&
				window.screen.orientation.angle !== -180
			) {
				setOrientation("landscape");
			} else {
				setOrientation("portrait");
			}

			window.screen.orientation.onchange = () => {
				// console.log(window.screen.orientation.angle);
				if (
					window.screen.orientation.angle !== 0 &&
					window.screen.orientation.angle !== 180 &&
					window.screen.orientation.angle !== -180
				) {
					setOrientation("landscape");
				} else {
					setOrientation("portrait");
				}
			};
		} else {
			// console.log("Intra in else");
			if (
				window.orientation !== 0 &&
				window.orientation !== 180 &&
				window.orientation !== -180
			) {
				setOrientation("landscape");
			} else {
				setOrientation("portrait");
			}

			window.onorientationchange = () => {
				if (
					window.orientation !== 0 &&
					window.orientation !== 180 &&
					window.orientation !== -180
				) {
					setOrientation("landscape");
				} else {
					setOrientation("portrait");
				}
			};
		}
		if (props.page === "login" && Cookies.get(config.cookie_token_key)) {
			window.open("/welcome", "_self");
		}
		document.querySelector("body").style.padding = 0;
		document.querySelector("body").style.backgroundImage = "none";
		document.querySelector("body").style.overflow = "visible";
		window.addEventListener("resize", setHeightWidth);
		window.addEventListener("orientationchange", setHeightWidth);
	}, []);

	useEffect(() => {
		setHeightWidth();
	}, [orientation]);

	return (
		<>
			{isMobileDevice() &&
			props.page == "login" &&
			orientation === props.correctOrientation ? (
				props.children
			) : isMobileDevice() &&
			  props.page == "login" &&
			  orientation !== props.correctOrientation ? (
				<RotateScreen
					display={
						isMobileDevice() && orientation === props.correctOrientation
							? "none"
							: "flex"
					}
				/>
			) : (
				<>
					<div
						className="mobile-landscape-container"
						style={{
							display:
								isMobileDevice() && orientation === props.correctOrientation
									? "block"
									: "none",
						}}
					>
						<NavbarComponent noNavbar={props.noNavbar} />
						{windowWidth &&
							windowHeight &&
							React.cloneElement(props.children, {
								...props.children.props,
								windowHeight: windowHeight,
								windowWidth: windowWidth,
							})}
					</div>
					<RotateScreen
						display={
							isMobileDevice() && orientation === props.correctOrientation
								? "none"
								: "flex"
						}
					/>
				</>
			)}
		</>
	);
}
