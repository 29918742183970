import React from "react";
import CancelSubscriptionIn14DaysForm from "../components/forms/CancelSubscriptionIn14DaysForm";
import PopoverForm from "../components/PopoverForm";

export default function CancelSubscription() {
	return (
		<PopoverForm
			title="Formular de returnare a banilor."
			openButtonContent={<></>}
			openButtonClassName="open-popup"
			form={<CancelSubscriptionIn14DaysForm />}
			keepOpen={true}
			popupClassName="big-popup"
		/>
	);
}
