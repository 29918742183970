import React, { useRef, useState, useEffect } from "react";
// import "../styles/login-page.css";
import Cookies from "js-cookie";
import config from "../config";
export function Logout() {
	useEffect(() => {
		Cookies.remove(config.cookie_token_key);
		window.open("/", "_self");
	}, []);

	return <></>;
}
