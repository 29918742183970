import React from "react";
import { useEffect } from "react";
import "../styles/clouds.css";
import { useState } from "react";
import { getRndInteger, shuffle } from "../scripts/helpers";

export default function Clouds(props) {
	const animationDurationMinimum = props.animationDurationMinimum
		? props.animationDurationMinimum
		: 20;
	const animationDurationMaximum = props.animationDurationMaximum
		? props.animationDurationMaximum
		: 30;
	const animationDelayMinimum = 2;
	const animationDelayMaximum = 6;
	const scaleMinimum = 80;
	const scaleMaximum = 120;

	const [clouds, setClouds] = useState(
		props.type == "norisor"
			? [
					{ nor: "norisor_1.svg" },
					{ nor: "norisor_2.svg" },
					{ nor: "norisor_3.svg" },
					{ nor: "norisor_4.svg" },
					{ nor: "norisor_5.svg" },
					{ nor: "norisor_1.svg" },
					{ nor: "norisor_2.svg" },
					{ nor: "norisor_3.svg" },
					{ nor: "norisor_4.svg" },
					{ nor: "norisor_5.svg" },
					{ nor: "norisor_1.svg" },
					{ nor: "norisor_2.svg" },
					{ nor: "norisor_3.svg" },
					{ nor: "norisor_4.svg" },
					{ nor: "norisor_5.svg" },
			  ]
			: [
					{ nor: "nor_1.svg" },
					{ nor: "nor_2.svg" },
					{ nor: "nor_3.svg" },
					{ nor: "nor_4.svg" },
					{ nor: "nor_5.svg" },
					{ nor: "nor_6.svg" },
					{ nor: "nor_7.svg" },
					{ nor: "nor_8.svg" },
					{ nor: "nor_9.svg" },
					{ nor: "nor_1.svg" },
					{ nor: "nor_2.svg" },
					{ nor: "nor_3.svg" },
					{ nor: "nor_4.svg" },
					{ nor: "nor_5.svg" },
					{ nor: "nor_6.svg" },
			  ]
	);

	useEffect(() => {
		let cloudsTemp = [...clouds];
		let containerHeight = getComputedStyle(
			document.querySelector(".clouds")
		).height;
		let imageHeight =
			window.innerHeight * 0.3 < 300 ? window.innerHeight * 0.3 : 300;
		cloudsTemp.forEach((cloud, i) => {
			let top = `${getRndInteger(
				0,
				parseInt(containerHeight) - parseInt(imageHeight)
			)}px`;
			let animationDuration = getRndInteger(
				animationDurationMinimum,
				animationDurationMaximum
			);
			let animationDelay =
				getRndInteger(animationDelayMinimum, animationDelayMaximum) * i;
			let animation = `cloud-animation ${animationDuration}s ${animationDelay}s linear infinite`;
			let scale = getRndInteger(scaleMinimum, scaleMaximum) / 100;
			cloud.animation = animation;
			cloud.top = top;
			cloud.scale = scale;
		});
		setClouds(cloudsTemp);
	}, []);

	return (
		<div className="clouds" style={props.style}>
			{clouds.map(({ nor, top, animation, scale }) => {
				return (
					<img
						style={{
							top: top,
							animation: animation,
							transform: `scale(${props.scale ? props.scale : scale})`,
						}}
						src={require(`../assets/nori/${nor}`).default}
					/>
				);
			})}
		</div>
	);
}
