import React, { useEffect, useState } from "react";
import axios from "axios";
import {
	createArrayOfIncrementingNumbers,
	getApiConfig,
} from "../../scripts/helpers";
import { Link } from "react-router-dom";
import { Collapse } from "antd";
import "../../styles/antd.css";
import DocumentSvg from "../DocumentSvg";
const { Panel } = Collapse;

export default function CancelSubscriptionIn14DaysForm({ subscriptionType }) {
	const [activeForm, setActiveForm] = useState("form1");

	return (
		<>
			<div style={{ display: "flex" }}>
				<div
					style={{
						position: "absolute",
						top: "2rem",
						left: "2rem",
						height: "3rem",
						width: "3rem",
					}}
					className="back-button-retragere"
				>
					<Link to={-1}>
						<img src={require("../../assets/buton.svg").default} />
					</Link>
				</div>
				{/* <div
					className={`choice-button ${
						activeForm === "form1" ? "active-choice-button" : ""
					}`}
					onClick={() => {
						setActiveForm("form1");
					}}
				>
					Descarca cererea
				</div> */}
				{/* <div
					className={`choice-button ${
						activeForm === "form2" ? "active-choice-button" : ""
					}`}
					onClick={() => {
						setActiveForm("form2");
					}}
				>
					Completeaza formularul online
				</div> */}
			</div>
			{activeForm === "form1" && (
				<div className="form-container retragere-form">
					<h2>1. Descărcați formularul de retragere de mai jos: </h2>
					<a href="https://api.wikismarts.com/resurse/formular_retragere.pdf">
						{/* <img src={require("../../assets/form.svg").default} /> */}
						<DocumentSvg />
					</a>
					<h2>2. Printați formularul și completați datele necesare.</h2>
					<h2>
						3. Trimiteți formularul completat pe adresa de email{" "}
						<span style={{ color: "rgb(0, 157, 220)" }}>
							wiki@wikismarts.com
						</span>
						.
					</h2>
				</div>
			)}
			{activeForm === "form2" && <></>}
		</>
	);
}
