import "../../styles/navbar__mobile.css";
import BurgerMenu from "./BurgerMenu";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

export default function Navbar__Mobile({ name }) {
	return (
		<div className="navbar__mobile">
			<img
				src={require("../../assets/logo_WS/logo_wiki_white.svg").default}
				onClick={() => {
					window.location.href = "/welcome";
				}}
			/>
			<ToastContainer
				position="top-right"
				autoClose={5000}
				hideProgressBar={false}
				newestOnTop={false}
				closeOnClick
				rtl={false}
				pauseOnFocusLoss
				draggable
				pauseOnHover
				theme="dark"
			/>
			<BurgerMenu name={name} />
		</div>
	);
}
