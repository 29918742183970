import React from "react";
import NavbarComponent from "./Navbar/NavbarComponent";
import { useEffect } from "react";
import { useState } from "react";

export default function DesktopContainer(props) {
	console.log(props);
	return (
		<div className="desktop-container">
			{props.noNavbar ? "" : <NavbarComponent />}
			{React.cloneElement(props.children, {
				...props.children.props,
			})}
		</div>
	);
}
