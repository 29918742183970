import { Button, Steps } from "antd";
import axios from "axios";
import React, { useState } from "react";
import {
	createArrayOfIncrementingNumbers,
	getApiConfig,
} from "../../../scripts/helpers";
import { toast } from "react-toastify";
import BackButton from "../../svg-uri/BackButton";
import ReactPixel from "react-facebook-pixel";
import { Link } from "react-router-dom";

export default function SubscriptionInformationsFormMobile({
	subscriptionType,
}) {
	const [name, setName] = useState("");
	const [bank, setBank] = useState("");
	const [account, setAccount] = useState("");
	const [CUI, setCUI] = useState("");
	const [regCom1, setRegCom1] = useState("J");
	const [regCom2, setRegCom2] = useState("0");
	const [regCom3, setRegCom3] = useState("");
	const [regCom4, setRegCom4] = useState("2022");
	const [companyName, setCompanyName] = useState("");
	const [phone, setPhone] = useState("");
	const [judet, setJudet] = useState("");
	const [localitate, setLocalitate] = useState("");
	const [adress, setAdress] = useState("");
	const [activeForm, setActiveForm] = useState("fizica");
	const currentDate = new Date();
	const currentYear = currentDate.getFullYear();

	function handleBillingInformationsJuridica(e) {
		e.preventDefault();
		let body = {
			subscriptionType: subscriptionType,
			companyName: companyName,
			cui: CUI,
			registrationCode: regCom1 + "/" + regCom2 + "/" + regCom3 + "/" + regCom4,
			bank: bank,
			bankAccount: account,
			county: judet,
			city: localitate,
			address: adress,
		};
		handleBuySubscription(body);
	}

	function handleBillingInformationsFizica(e) {
		e.preventDefault();
		let phoneTemp = "40" + phone;
		console.log(phoneTemp);
		let body = {
			subscriptionType: subscriptionType,
			name: name,
			phone: phoneTemp,
			county: judet,
			city: localitate,
			address: adress,
		};
		// console.log(body);
		handleBuySubscription(body);
	}

	const handleBuySubscription = async (body) => {
		let response = await axios
			.post(`${getApiConfig().baseUrl}/subscriptions/start`, body, {
				headers: getApiConfig().headers,
			})
			.then((response) => {
				let data = response.data;
				// console.log(data);
				ReactPixel.trackCustom("Initiated Purchase", {
					...data,
					content_name: "Subscription Information Page",
					currency: "RON",
					content_category: subscriptionType,
				});
				window.location.href = data.formUrl;
			});
	};

	//----------------------------------------- general components for mobile form
	const steps = {
		fizica: [
			{
				title: "Nume",
				id: "persoana-fizica-name",
				sectionTitle: "Persoana de contact",
				content: (
					<div id="form-input-container" className="form-input-container">
						<input
							type="text"
							name="persoana-fizica-name"
							id="persoana-fizica-name"
							value={name}
							onChange={(e) => setName(e.target.value)}
							placeholder="Nume"
							required
						/>
					</div>
				),
			},
			{
				title: "Telefon",
				id: "persoana-fizica-phone",
				sectionTitle: "Persoana de contact",
				content: (
					<div id="form-input-container" className="form-input-container">
						<div className="input-with-prefix">
							<div>+40</div>
							<input
								type="tel"
								id="persoana-fizica-phone"
								name="persoana-fizica-phone"
								placeholder="*********"
								required
								pattern="[0-9]{9}"
								value={phone}
								onChange={(e) => {
									let value = e.target.value;
									value = value.replace(/[^a-zA-Z0-9 ]/g, "");
									setPhone(value);
								}}
							/>
							<span></span>
						</div>
					</div>
				),
			},
			{
				title: "Judet",
				id: "persoana-fizica-judet",
				sectionTitle: "Adresa de facturare",
				content: (
					<div id="form-input-container" className="form-input-container">
						<input
							type="text"
							id="persoana-fizica-judet"
							name="persoana-fizica-judet"
							placeholder="Judet"
							required
							onChange={(e) => setJudet(e.target.value)}
						/>
					</div>
				),
			},
			{
				title: "Localitate",
				id: "persoana-fizica-localitate",
				sectionTitle: "Adresa de facturare",
				content: (
					<div id="form-input-container" className="form-input-container">
						<input
							type="text"
							id="persoana-fizica-localitate"
							name="persoana-fizica-localitate"
							placeholder="Localitate"
							required
							onChange={(e) => setLocalitate(e.target.value)}
						/>
					</div>
				),
			},
			{
				title: "Adresa",
				id: "persoana-fizica-adress",
				sectionTitle: "Adresa de facturare",
				content: (
					<div id="form-input-container" className="form-input-container">
						<input
							type="text"
							id="persoana-fizica-adress"
							name="persoana-fizica-adress"
							placeholder="Adresa"
							required
							onChange={(e) => setAdress(e.target.value)}
						/>
					</div>
				),
			},
		],
		juridica: [
			// {
			// 	title: "Tip",
			// 	sectionTitle: "Tip de facturare",
			// 	content: (
			// 		<div style={{ display: "flex" }}>
			// 			<div
			// 				className={`choice-button-mobile choice-button ${
			// 					activeForm === "fizica" ? "active-choice-button" : ""
			// 				}`}
			// 				onClick={() => {
			// 					setActiveForm("fizica");
			// 				}}
			// 			>
			// 				Persoana fizica
			// 			</div>
			// 			<div
			// 				className={`choice-button-mobile choice-button ${
			// 					activeForm === "juridica" ? "active-choice-button" : ""
			// 				}`}
			// 				onClick={() => {
			// 					setActiveForm("juridica");
			// 				}}
			// 			>
			// 				Persoana juridica
			// 			</div>
			// 		</div>
			// 	),
			// },
			{
				title: "Companie",
				id: "persoana-juridica-company-name",
				sectionTitle: "Informatii companie",
				content: (
					<div id="form-input-container" className="form-input-container">
						<input
							type="text"
							id="persoana-juridica-company-name"
							name="persoana-juridica-company-name"
							placeholder="Companie"
							required
							onChange={(e) => setCompanyName(e.target.value)}
						/>
					</div>
				),
			},
			{
				title: "CUI",
				id: "persoana-juridica-CUI",
				sectionTitle: "Informatii companie",
				content: (
					<div id="form-input-container" className="form-input-container">
						<input
							type="text"
							id="persoana-juridica-CUI"
							name="persoana-juridica-CUI"
							placeholder="CUI"
							required
							onChange={(e) => setCUI(e.target.value)}
						/>
					</div>
				),
			},
			{
				title: "Numar",
				id: "persoana-juridica-reg-com-1",
				sectionTitle: "Numar de inregistrare in Registrul Comertului",
				content: (
					<div className="form-inputs-side-by-side-4">
						<select
							name="persoana-juridica-reg-com-1"
							id="persoana-juridica-reg-com-1"
							onChange={(e) => setRegCom1(e.target.value)}
						>
							<option value="J">J</option>
							<option value="F">F</option>
							<option value="C">C</option>
							<option value="" selected>
								-
							</option>
						</select>
						<select
							name="persoana-juridica-reg-com-2"
							id="persoana-juridica-reg-com-2"
							onChange={(e) => setRegCom2(e.target.value)}
						>
							{createArrayOfIncrementingNumbers(0, 52).map((nr) => {
								return <option value={nr}>{nr}</option>;
							})}
						</select>
						<input
							type="text"
							id="persoana-juridica-reg-com-3"
							name="persoana-juridica-reg-com-3"
							placeholder=""
							onChange={(e) => setRegCom3(e.target.value)}
						/>
						<select
							name="persoana-juridica-reg-com-4"
							id="persoana-juridica-reg-com-4"
							onChange={(e) => setRegCom4(e.target.value)}
						>
							{createArrayOfIncrementingNumbers(
								1990,
								parseInt(currentYear),
								0
							).map((nr) => {
								return <option value={nr}>{nr}</option>;
							})}
						</select>
					</div>
				),
			},
			{
				title: "Banca",
				id: "persoana-juridica-bank",
				sectionTitle: "Informatii companie",
				content: (
					<div id="form-input-container" className="form-input-container">
						<input
							type="text"
							id="persoana-juridica-bank"
							name="persoana-juridica-bank"
							placeholder="Banca"
							onChange={(e) => setBank(e.target.value)}
						/>
					</div>
				),
			},
			{
				title: "Cont",
				id: "persoana-juridica-account",
				sectionTitle: "Informatii companie",
				content: (
					<div id="form-input-container" className="form-input-container">
						<input
							type="text"
							id="persoana-juridica-account"
							name="persoana-juridica-account"
							placeholder="Cont"
							onChange={(e) => setAccount(e.target.value)}
						/>
					</div>
				),
			},
			{
				title: "Judet",
				id: "persoana-juridica-judet",
				sectionTitle: "Sediu social",
				content: (
					<div id="form-input-container" className="form-input-container">
						<input
							type="text"
							id="persoana-juridica-judet"
							name="persoana-juridica-judet"
							placeholder="Judet"
							required
							onChange={(e) => setJudet(e.target.value)}
						/>
					</div>
				),
			},
			{
				title: "Localitate",
				id: "persoana-juridica-localitate",
				sectionTitle: "Sediu social",
				content: (
					<div id="form-input-container" className="form-input-container">
						<input
							type="text"
							id="persoana-juridica-localitate"
							name="persoana-juridica-localitate"
							placeholder="Localitate"
							required
							onChange={(e) => setLocalitate(e.target.value)}
						/>
					</div>
				),
			},
			{
				title: "Adresa",
				id: "persoana-juridica-adress",
				sectionTitle: "Sediu social",
				content: (
					<div id="form-input-container" className="form-input-container">
						<input
							type="text"
							id="persoana-juridica-adress"
							name="persoana-juridica-adress"
							placeholder="Adresa"
							required
							onChange={(e) => setAdress(e.target.value)}
						/>
					</div>
				),
			},
		],
	};
	const [current, setCurrent] = useState(0);
	const [status, setStatus] = useState();

	const next = () => {
		if (checkRequiredFill()) {
			setCurrent(current + 1);
		}
	};

	const prev = () => {
		setCurrent(current - 1);
	};

	const items = steps[activeForm].map((item) => ({
		key: item.title,
		title: item.title,
	}));

	function checkRequiredFill(index, toastActive = true) {
		let selectedIndex = index ? index : current;
		if (steps[activeForm][selectedIndex].id) {
			let input = document.querySelector(
				`#${steps[activeForm][selectedIndex].id}`
			);
			if (
				input.required &&
				((input.type != "checkbox" && input.value == "") ||
					(input.type == "checkbox" && !input.checked))
			) {
				setStatus("error");
				if (toastActive) {
					toast.warn(
						`Campul "${steps[activeForm][selectedIndex].title}" este obligtoriu`,
						{
							autoClose: 2000,
						}
					);
				}
				return 0;
			} else {
				if (!input.checkValidity()) {
					setStatus("error");
					if (toastActive) {
						toast.warn(
							`Campul "${steps[activeForm][selectedIndex].title}" nu are un format corect`,
							{
								autoClose: 2000,
							}
						);
					}
					return 0;
				} else setStatus("");
				return 1;
			}
		}
		return 1;
	}

	const onChange = (newValue, oldValue) => {
		let min = Math.min(newValue, oldValue);
		let max = Math.max(newValue, oldValue);
		for (let i = min; i < max; i++)
			if (!checkRequiredFill(i, false)) {
				setCurrent(i);
				toast.warn("Va rugam sa completati in ordine toate campurile.");
				return;
			}
		setCurrent(newValue);
	};

	return (
		<>
			<div className="steps-container">
				<h3
					style={{
						position: "relative",
						alignItems: "center",
						display: "flex",
					}}
				>
					<div className="back-button mobile-form-back-button">
						<Link to={-1}>
							<BackButton />
						</Link>
					</div>
					{steps[activeForm][current].sectionTitle}
					<div style={{ display: "flex", marginLeft: "auto" }}>
						<div
							className={`choice-button-mobile choice-button ${
								activeForm === "fizica" ? "active-choice-button" : ""
							}`}
							onClick={() => {
								setActiveForm("fizica");
								setCurrent(1);
							}}
						>
							Persoana fizica
						</div>
						<div
							className={`choice-button-mobile choice-button ${
								activeForm === "juridica" ? "active-choice-button" : ""
							}`}
							onClick={() => {
								setActiveForm("juridica");
								setCurrent(1);
							}}
						>
							Persoana juridica
						</div>
					</div>
				</h3>
				<Steps
					current={current}
					status={status}
					items={items}
					onChange={(value) => {
						onChange(value, current);
					}}
				/>
				<form className="form-mobile">
					<Button disabled={current <= 0} onClick={() => prev()}>
						Anteriorul
					</Button>
					{steps[activeForm].map((step, i) => {
						return (
							<div
								key={step.title}
								style={{
									display: i === current ? "block" : "none",
									width: "100%",
								}}
							>
								{step.content}
							</div>
						);
					})}
					{current < steps[activeForm].length - 1 && (
						<Button
							style={{ marginLeft: "auto" }}
							type="primary"
							onClick={() => next()}
						>
							Urmatorul
						</Button>
					)}
					{current === steps[activeForm].length - 1 && (
						<Button
							style={{ marginLeft: "auto" }}
							type="primary"
							onClick={(e) => {
								if (checkRequiredFill())
									activeForm == "fizica"
										? handleBillingInformationsFizica(e)
										: handleBillingInformationsJuridica(e);
							}}
						>
							Finalizeaza
						</Button>
					)}
				</form>
			</div>
		</>
	);
}
