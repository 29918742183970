import React, { useState, useEffect } from "react";
import "../styles/loading-screen.css";

export default function LoadingBubbles() {
	const animationsList = [
		"bubblejump 0.5s ease, bubblejump2  0.5s 2s ease",
		"bubblejump  0.5s 0.25s ease, bubblejump  0.5s 1.75s ease",
		"bubblejump  0.5s 0.5s ease, bubblejump  0.5s 1.5s ease",
		"bubblejump  0.5s 0.75s ease, bubblejump  0.5s 1.25s ease",
		"bubblejump  0.5s 1s ease",
	];
	const [animations, setAnimations] = useState(0);

	useEffect(() => {
		if (!animations) {
			setAnimations(animationsList);
		}
	}, [animations]);

	return (
		<>
			<div
				style={{
					backgroundColor: "rgb(255, 208, 52)",
					animation: animations[0],
				}}
				className="loading-screen-bubble"
				onAnimationEnd={(e) => {
					if (e.animationName === "bubblejump2") {
						setAnimations(0);
					}
				}}
			></div>
			<div
				style={{
					backgroundColor: "rgb(215, 7, 121)",
					animation: animations[1],
				}}
				className="loading-screen-bubble"
			></div>
			<div
				style={{
					backgroundColor: "rgb(24, 194, 156)",
					animation: animations[2],
				}}
				className="loading-screen-bubble"
			></div>
			<div
				style={{
					backgroundColor: "rgb(15, 202, 232)",
					animation: animations[3],
				}}
				className="loading-screen-bubble"
			></div>
			<div
				style={{
					backgroundColor: "rgb(0, 138, 201)",
					animation: animations[4],
				}}
				className="loading-screen-bubble"
			></div>
		</>
	);
}
