import React from "react";
import { useEffect } from "react";
import NewPassword from "../components/forms/NewPassword";
import PopoverForm from "../components/PopoverForm";

export default function ResetPassword() {
	useEffect(() => {
		document.querySelector(".new-password-button").click();
	}, []);
	return (
		<PopoverForm
			title={
				<img src={require("../assets/logo_WS/logo_wiki_side.svg").default} />
			}
			openButtonContent={<></>}
			openButtonClassName="user-tab  pretty-button open-popup new-password-button"
			keepOpen={true}
			form={<NewPassword />}
		/>
	);
}
