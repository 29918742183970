import React from "react";
import LoadingBubbles from "../components/LoadingBubbles";

export function Loading() {
	return (
		<div className="loading-screen">
			<img
				src={require("../assets/background.svg").default}
				className="background-image-circles"
			/>
			<LoadingBubbles />
		</div>
	);
}
