import React from "react";
import "../../styles/back-button.css";

export default function BackButton(props) {
	return (
		<svg
			xmlns="http://www.w3.org/2000/svg"
			xmlnsXlink="http://www.w3.org/1999/xlink"
			id="Layer_1"
			x="0px"
			y="0px"
			viewBox="0 0 800 800"
			style={{
				enableBackground: "new 0 0 800 800",
			}}
			xmlSpace="preserve"
			role="img"
		>
			<style type="text/css">
				{".st4back{fill:#FFFFFF;}\n\t.st5back{fill:#D1BFCD;}\n"}
			</style>
			<g>
				<g>
					<g />
				</g>
				<g className="back-arrow">
					<g>
						<path
							className="st4back"
							d="M600.4,434.6c0,51.7-30.4,96.3-74.2,116.8c-4.4,2-9.4-1.2-9.4-6v-17.4c-0.2-34.1-28.8-61.3-63-61.3h-21.5     v71.1c0,19.4-22,30.6-37.8,19.3L189,409.1c-13.2-9.5-13.2-29.1,0-38.6l205.5-148c15.8-11.3,37.8-0.1,37.8,19.3v63.6h36.2     c1.6,0,3.2,0.1,4.7,0.3c10.1,0.2,19.9,1.5,29.3,3.7C558.7,323.4,600.4,374.1,600.4,434.6z"
						/>
					</g>
					<g>
						<path
							className="st5back"
							d="M508.9,498.6c-10.8-19.1-31.7-31.9-55.1-31.9h-21.6v71c0,19.4-22,30.7-37.7,19.3L189,409.1     c-9.1-6.6-12-18-8.4-27.6c0.2,0.2,0.4,0.3,0.6,0.5l205.5,148c15.8,11.3,37.7,0.1,37.7-19.3v-71H446     C479.3,439.6,507.4,465.5,508.9,498.6z"
						/>
						<path
							className="st5back"
							d="M600.3,434.7c0,51.7-30.4,96.3-74.2,116.8c-4.4,2-9.3-1.2-9.3-6v-17.4c0-1.1-0.1-2.1-0.1-3.2v-0.1     c0.5-0.1,1.1-0.2,1.6-0.5c43.9-20.5,74.2-65.1,74.2-116.8c0-7.5-0.7-14.9-1.9-22.1C596.9,400.6,600.3,417.2,600.3,434.7z"
						/>
					</g>
				</g>
			</g>
		</svg>
	);
}
