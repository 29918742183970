import axios from "axios";
import React, { useState, useEffect } from "react";
import { isMobileDevice } from "../scripts/helpers";
import "../styles/popover.css";

export default function PopoverForm(props) {
	const [visible, setVisible] = useState(false);
	const [formTranslate, setFormTranslate] = useState(0);
	function overlayClick(e) {
		if (e.target.id === "popup-overlay" && !props.keepOpen) {
			setVisible(false);
		}
	}

	axios.interceptors.response.use((res) => {
		if (res.request.responseURL.includes("changePassword")) {
			setVisible(false);
		}
		return res;
	});

	function goToPrev() {
		setFormTranslate((formTranslate) => {
			return formTranslate + 100;
		});
	}

	function goToNext() {
		console.log(formTranslate);
		let newFormTranslate = formTranslate;
		newFormTranslate -= 100;
		setFormTranslate(newFormTranslate);
	}

	useEffect(() => {
		if (props.onVisibleChange) props.onVisibleChange(visible);
	}, [visible]);

	useEffect(() => {
		if (visible) {
			document.querySelectorAll(".next-input").forEach((next) => {
				next.addEventListener("click", goToNext);
			});
			document.querySelectorAll(".prev-input").forEach((prev) => {
				prev.addEventListener("click", goToPrev);
			});
		}
	}, [visible]);

	useEffect(() => {
		console.log(formTranslate);
		if (document.querySelector(".mobile-popup form"))
			document.querySelector(
				".mobile-popup form"
			).style.transform = `translateX(${formTranslate}vw)`;
	}, [formTranslate]);

	return (
		<>
			<div
				onClick={() => setVisible(true)}
				className={props.openButtonClassName}
				style={props.openButtonStyle}
			>
				{props.openButtonContent}
			</div>
			{(visible || props.keepOpen) && (
				<div
					className="popup-overlay"
					id="popup-overlay"
					onClick={overlayClick}
				>
					<div
						className={`popup ${isMobileDevice() ? "mobile-popup" : ""} ${
							props.popupClassName
						}`}
					>
						<div className="form-container">
							<h3 className="popup-title">{props.title}</h3>
							{React.cloneElement(props.form, {
								...props.form.props,
								setVisible: setVisible,
							})}
							{/* {props.form} */}
						</div>
					</div>
				</div>
			)}
		</>
	);
}
