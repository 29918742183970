import React, { useEffect, useState } from "react";
import axios from "axios";
import {
	createArrayOfIncrementingNumbers,
	getApiConfig,
} from "../../scripts/helpers";
import Cookies from "js-cookie";
import config from "../../config";
import { Link } from "react-router-dom";
import ReactPixel from "react-facebook-pixel";

export default function SubscriptionInformationsForm({ subscriptionType }) {
	const [name, setName] = useState("");
	const [bank, setBank] = useState("");
	const [account, setAccount] = useState("");
	const [CUI, setCUI] = useState("");
	const [regCom1, setRegCom1] = useState("J");
	const [regCom2, setRegCom2] = useState("0");
	const [regCom3, setRegCom3] = useState("");
	const [regCom4, setRegCom4] = useState("2022");
	const [companyName, setCompanyName] = useState("");
	const [phone, setPhone] = useState("");
	const [judet, setJudet] = useState("");
	const [localitate, setLocalitate] = useState("");
	const [adress, setAdress] = useState("");
	const [activeForm, setActiveForm] = useState("fizica");
	const currentDate = new Date();
	const currentYear = currentDate.getFullYear();
	function handleBillingInformationsJuridica(e) {
		e.preventDefault();
		let body = {
			subscriptionType: subscriptionType,
			companyName: companyName,
			cui: CUI,
			registrationCode: regCom1 + "/" + regCom2 + "/" + regCom3 + "/" + regCom4,
			bank: bank,
			bankAccount: account,
			county: judet,
			city: localitate,
			address: adress,
		};
		handleBuySubscription(body);
	}
	function handleBillingInformationsFizica(e) {
		e.preventDefault();
		let phoneTemp = "40" + phone;
		console.log(phoneTemp);
		let body = {
			subscriptionType: subscriptionType,
			name: name,
			phone: phoneTemp,
			county: judet,
			city: localitate,
			address: adress,
		};
		// console.log(body);
		handleBuySubscription(body);
	}

	const handleBuySubscription = async (body) => {
		let response = await axios
			.post(`${getApiConfig().baseUrl}/subscriptions/start`, body, {
				headers: getApiConfig().headers,
			})
			.then((response) => {
				let data = response.data;
				// console.log(data);
				ReactPixel.trackCustom("Initiated Purchase", {
					...data,
					content_name: "Subscription Information Page",
					currency: "RON",
					content_category: subscriptionType,
				});
				window.location.href = data.formUrl;
			});
	};

	return (
		<>
			<div style={{ display: "flex" }}>
				<div
					style={{
						position: "absolute",
						top: "0",
						left: "0",
						height: "2rem",
						width: "2rem",
					}}
				>
					<Link to={-1}>
						<img src={require("../../assets/buton.svg").default} />
					</Link>
				</div>
				<div
					className={`choice-button ${
						activeForm === "fizica" ? "active-choice-button" : ""
					}`}
					onClick={() => {
						setActiveForm("fizica");
					}}
				>
					Persoana fizica
				</div>
				<div
					className={`choice-button ${
						activeForm === "juridica" ? "active-choice-button" : ""
					}`}
					onClick={() => {
						setActiveForm("juridica");
					}}
				>
					Persoana juridica
				</div>
			</div>
			{activeForm === "fizica" && (
				<form
					className="persoana-fizica-form"
					onSubmit={handleBillingInformationsFizica}
				>
					<h3 style={{ margin: 0, marginTop: "1rem" }}>Persoana de contact</h3>
					<div className="form-inputs-side-by-side">
						<div id="form-input-container" className="form-input-container">
							{/* <label htmlFor="persoana-fizica-name">Nume si Prenume</label> */}
							<input
								type="text"
								id="persoana-fizica-name"
								name="persoana-fizica-name"
								placeholder="Nume si Prenume"
								onChange={(e) => setName(e.target.value)}
								required
							/>
						</div>
						<div id="form-input-container" className="form-input-container">
							{/* <label htmlFor="persoana-fizica-phone">Telefon</label> */}
							<div className="input-with-prefix">
								<div>+40</div>
								<input
									type="tel"
									id="persoana-fizica-phone"
									name="persoana-fizica-phone"
									placeholder=" Numar de telefon"
									required
									pattern="[0-9]{9}"
									value={phone}
									onChange={(e) => {
										let value = e.target.value;
										value = value.replace(/[^a-zA-Z0-9 ]/g, "");
										setPhone(value);
									}}
								/>
								<span></span>
							</div>
						</div>
					</div>
					<h3 style={{ margin: 0, marginTop: "1rem" }}>Adresa de facturare</h3>
					<div className="form-inputs-side-by-side">
						<div id="form-input-container" className="form-input-container">
							{/* <label htmlFor="persoana-fizica-judet">Judet</label> */}
							<input
								type="text"
								id="persoana-fizica-judet"
								name="persoana-fizica-judet"
								placeholder="Judet"
								required
								onChange={(e) => setJudet(e.target.value)}
							/>
						</div>
						<div id="form-input-container" className="form-input-container">
							{/* <label htmlFor="persoana-fizica-localitate">Localitate</label> */}
							<input
								type="text"
								id="persoana-fizica-localitate"
								name="persoana-fizica-localitate"
								placeholder="Localitate"
								required
								onChange={(e) => setLocalitate(e.target.value)}
							/>
						</div>
					</div>
					<div id="form-input-container" className="form-input-container">
						{/* <label htmlFor="persoana-fizica-adress">Adresa</label> */}
						<input
							type="text"
							id="persoana-fizica-adress"
							name="persoana-fizica-adress"
							placeholder="Adresa"
							required
							onChange={(e) => setAdress(e.target.value)}
						/>
					</div>

					<button type="submit" className="pretty-button">
						Continuă
					</button>
				</form>
			)}
			{activeForm === "juridica" && (
				<form
					className="persoana-juridica-form"
					onSubmit={handleBillingInformationsJuridica}
				>
					<h3 style={{ margin: 0, marginTop: "1rem" }}>Informatii companie</h3>
					<div className="form-inputs-side-by-side">
						<div id="form-input-container" className="form-input-container">
							{/* <label htmlFor="persoana-juridica-company-name">
								Nume companie
							</label> */}
							<input
								type="text"
								id="persoana-juridica-company-name"
								name="persoana-juridica-company-name"
								placeholder="Nume companie"
								required
								onChange={(e) => setCompanyName(e.target.value)}
							/>
						</div>
						<div id="form-input-container" className="form-input-container">
							{/* <label htmlFor="persoana-juridica-CUI">
								Cod Unic de Inregistrare
							</label> */}
							<input
								type="text"
								id="persoana-juridica-CUI"
								name="persoana-juridica-CUI"
								placeholder="Cod Unic de Inregistrare"
								required
								onChange={(e) => setCUI(e.target.value)}
							/>
						</div>
					</div>
					<div className="form-input-container">
						<label style={{ fontSize: "0.9rem" }}>
							Numar de inregistrare in Registrul Comertului
						</label>
						<div className="form-inputs-side-by-side-4">
							<select
								name="persoana-juridica-reg-com-1"
								id="persoana-juridica-reg-com-1"
								onChange={(e) => setRegCom1(e.target.value)}
							>
								<option value="J">J</option>
								<option value="F">F</option>
								<option value="C">C</option>
								<option value="" selected>
									-
								</option>
							</select>
							<select
								name="persoana-juridica-reg-com-2"
								id="persoana-juridica-reg-com-2"
								onChange={(e) => setRegCom2(e.target.value)}
							>
								{createArrayOfIncrementingNumbers(0, 52).map((nr) => {
									return <option value={nr}>{nr}</option>;
								})}
							</select>
							<input
								type="text"
								id="persoana-juridica-reg-com-3"
								name="persoana-juridica-reg-com-3"
								placeholder=""
								onChange={(e) => setRegCom3(e.target.value)}
							/>
							<select
								name="persoana-juridica-reg-com-4"
								id="persoana-juridica-reg-com-4"
								onChange={(e) => setRegCom4(e.target.value)}
							>
								{createArrayOfIncrementingNumbers(
									1990,
									parseInt(currentYear),
									0
								).map((nr) => {
									return <option value={nr}>{nr}</option>;
								})}
							</select>
						</div>
					</div>

					<div className="form-inputs-side-by-side">
						<div id="form-input-container" className="form-input-container">
							{/* <label htmlFor="persoana-juridica-bank">Banca</label> */}
							<input
								type="text"
								id="persoana-juridica-bank"
								name="persoana-juridica-bank"
								placeholder="Banca"
								onChange={(e) => setBank(e.target.value)}
							/>
						</div>
						<div id="form-input-container" className="form-input-container">
							{/* <label htmlFor="persoana-juridica-account">Cont</label> */}
							<input
								type="text"
								id="persoana-juridica-account"
								name="persoana-juridica-account"
								placeholder="Cont"
								onChange={(e) => setAccount(e.target.value)}
							/>
						</div>
					</div>

					<h3 style={{ margin: 0, marginTop: "1rem" }}>Sediu social</h3>
					<div className="form-inputs-side-by-side">
						<div id="form-input-container" className="form-input-container">
							{/* <label htmlFor="persoana-juridica-judet">Judet</label> */}
							<input
								type="text"
								id="persoana-juridica-judet"
								name="persoana-juridica-judet"
								placeholder="Judet"
								required
								onChange={(e) => setJudet(e.target.value)}
							/>
						</div>
						<div id="form-input-container" className="form-input-container">
							{/* <label htmlFor="persoana-juridica-localitate">Localitate</label> */}
							<input
								type="text"
								id="persoana-juridica-localitate"
								name="persoana-juridica-localitate"
								placeholder="Localitate"
								required
								onChange={(e) => setLocalitate(e.target.value)}
							/>
						</div>
					</div>
					<div id="form-input-container" className="form-input-container">
						{/* <label htmlFor="persoana-juridica-adress">Adresa</label> */}
						<input
							type="text"
							id="persoana-juridica-adress"
							name="persoana-juridica-adress"
							placeholder="Adresa"
							required
							onChange={(e) => setAdress(e.target.value)}
						/>
					</div>

					<button type="submit" className="pretty-button">
						Continuă
					</button>
				</form>
			)}
		</>
	);
}
