import React, { useEffect, useState } from "react";
import { logout } from "../../scripts/helpers";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Link, useNavigate } from "react-router-dom";
import { Button, Popover } from "antd";
import "../../styles/antd.css";

export default function Navbar() {
	const [open, setOpen] = useState(false);
	const navigate = useNavigate();

	function activePopover(e) {
		// // console.log("...");
		let logoutPopup = document.querySelector(".logout-popup");
		if (logoutPopup) {
			if (logoutPopup.classList.contains("logout-popup-visible")) {
				// // console.log("yes");
				if (!e.target.classList.contains("logout-item")) {
					logoutPopup.classList.remove("logout-popup-visible");
					document
						.querySelector("body")
						.removeEventListener("click", activePopover);
				}
			} else if (!logoutPopup.classList.contains("logout-popup-visible")) {
				// // console.log("no");
				logoutPopup.classList.add("logout-popup-visible");
			}
		} else {
			document
				.querySelector("body")
				.removeEventListener("click", activePopover);
		}
	}
	useEffect(() => {
		return () => {
			document
				.querySelector("body")
				.removeEventListener("click", activePopover);
		};
	}, []);

	useEffect(() => {
		// // console.log(open);
	}, [open]);

	return (
		<div className="navbar-row">
			<ToastContainer
				position="top-right"
				autoClose={5000}
				hideProgressBar={false}
				newestOnTop={false}
				closeOnClick
				rtl={false}
				pauseOnFocusLoss
				draggable
				pauseOnHover
				theme="dark"
			/>

			{/* <div className="button-navigation">
				<Link to={-1}>
					<img
						src={require("../../assets/buton.svg").default}
						style={{
							height: "100%",
						}}
					></img>
				</Link>
			</div> */}
			<nav className="navbar">
				<div className="navbar__inner">
					<div className="navbar__brand">
						<Link
							to="/welcome"
							style={{
								width: "100%",
								height: "100%",
								display: "flex",
								alignItems: "center",
							}}
						>
							<img
								src={
									require("../../assets/logo_WS/logo_wiki_side_white.svg")
										.default
								}
							/>
						</Link>
					</div>
					<div className="navbar__links">
						<div
							className="navbar__item"
							onClick={() => {
								navigate("/modules");
							}}
						>
							<img
								className="navbar__item-icon"
								src={require("../../assets/nav_bar/modules.svg").default}
								alt=""
							/>
							<h2>module</h2>
						</div>
						<div
							className="navbar__item"
							onClick={() => {
								navigate("/progress");
							}}
						>
							<img
								className="navbar__item-icon"
								src={require("../../assets/nav_bar/score.svg").default}
								alt=""
							/>
							<h2>rezultate</h2>
						</div>
						<Popover
							content={
								<div
									style={{
										display: "flex",
									}}
								>
									<div
										className="logout-popup-button logout-item"
										style={{
											backgroundColor: "#ff006e",
											margin: "0 auto",
											color: "white",
										}}
										onClick={logout}
									>
										Deconectare
									</div>
								</div>
							}
							title="Ești sigur că vrei să ieși din cont?"
							trigger="click"
							open={open}
							onClick={() => {
								setOpen(true);
							}}
						>
							<div className="navbar__item">
								<img
									className="navbar__item-icon"
									src={require("../../assets/nav_bar/logout.svg").default}
									alt=""
								/>
								<h2>deconectare</h2>
							</div>
						</Popover>
					</div>
				</div>
				<div className="navbar__profile">
					<Link to="/profile">
						<img
							src={require("./../../assets/mascot/wiki_avatar.png").default}
							alt=""
							style={{ cursor: "pointer" }}
						/>
					</Link>
				</div>
			</nav>
		</div>
	);
}
